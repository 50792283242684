
<template>
  <div>
    <!--    login proproxy    -->
    <!-- <div class="w-20 absolute z-10 m-16 flex items-center space-x-4">
      <img src="../../../assets/logo1.png"
          class="relative object-cover rounded-md lg:w-full md:w-[50px] w-[30px]">
      <div class="flex text-white font-bold brightness-200 lg:text-5xl md:text-3xl text-2xl">
        <p class="text-[#00daff]">Pro</p>Proxies
      </div>
    </div> -->
    <!--   global proproxy   -->
    <!-- <div class="w-[18rem] absolute z-10 m-16 flex items-center space-x-4">
      <img src="../../../assets/logo2.png"
          class="relative  object-cover rounded-md lg:w-full md:w-[200px] w-[150px]">
    </div> -->
    <!--   proxy hero   -->
    <div class="w-[14rem] absolute z-10 m-16 flex items-center space-x-4">
      <img src="../../../assets/logo4.png"
          class="relative  object-cover rounded-md lg:w-full md:w-[200px] w-[150px]">
    </div>
  </div>
  <div class="row justify-content-center h-screen items-center">
    <img src="../../../assets/images/sign_bg.png" class="absolute w-full h-full  object-cover p-0" />
    <div class="absolute h-1/2 w-auto z-10  aspect-square -translate-x-3/4 hidden lg:block  shadow-black rounded-3xl ">
      <!-- <div class="absolute flex justify-center items-center -translate-y-1/2 top-1/2">
        <img src="../../../assets/images/overload-bg.jpg"
          class="relative w-full h-full  object-cover rounded-md shadow-md shadow-black ">
      </div> -->
      <div class="absolute flex justify-center items-center -translate-y-1/2 top-1/2">
        <img src="../../../assets/images/overload-ring-1.png"
          class="relative w-full h-full  object-cover element-ring1-to-rotate">
      </div>
      <div class="absolute flex justify-center items-center -translate-y-1/2 top-1/2 p-4 rotate-45">
        <img src="../../../assets/images/overload-ring-2.png"
          class="relative w-full h-full  object-cover element-ring2-to-rotate">
      </div>
      <div
        class="text-white font-roboto text-3xl w-1/2 aspect-square left-1/2 top-1/2 absolute -translate-x-1/2 -translate-y-1/2 flex items-center">
        <div>
          <div class="text-nowrap flex"><p class="font-semibold text-sky-400">Flexible</p>&nbsp; pricing</div>
          <div class="text-nowrap flex"><p class="font-semibold text-sky-400">24 Hour</p>&nbsp; money-back</div>
          <div class="text-nowrap flex">Intuitive &nbsp;<p class="font-semibold text-sky-400">Self-service</p></div>
          <div class="text-nowrap flex"><p class="font-semibold text-sky-400">24/7 </p> &nbsp; support team</div>
        </div>
        <!-- <div>
          <div class="text-nowrap flex">The &nbsp;  <p class="font-semibold text-sky-400"> best </p></div>
          <div class="text-nowrap flex"><p class="font-semibold text-sky-400">4G and 5G</p>&nbsp; modems</div>
          <div class="text-nowrap flex">from  &nbsp;<p class="font-semibold text-sky-400">all</p></div>
          <div class="text-nowrap flex"><p class="font-semibold text-sky-400">over the </p> &nbsp; world!</div>
        </div> -->
      </div>
    </div>
    <div class="absolute h-full bottom-0 overflow-y-hidden flex items-center justify-center">
      <img src="../../../assets/images/sign-ring.png" class="absolute h-full aspect-square object-cover p-0 right-0 animate-pulse brightness-125" />
      <div class="lg:h-full aspect-square  absolute lg:right-0 sm:h-1/2 h-1/3">
        <div
          class="absolute   rounded-3xl left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 lg:w-1/2 w-full  mx-auto aspect-square flex justify-center items-center shadow-black px-4 text-sm">
          <div>
            <h4 class="d-block text-white fw-semibold mb-10 text-[20px] flex text-center">Forgot Password?</h4>
            <p class=" d-block text-white fw-semibold mb-20 text-center">
              Enter your email address below and we will send you the reset password link
            </p>
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="flex justify-between">
                <div class="w-full pl-2 mb-sm-20 mb-md-25">
                  <label class="d-block text-white fw-semibold mb-10">Email</label>
                  <Field name="email" v-slot="{ field }">
                    <input v-bind="field" type="email" name="email"
                      class="w-full shadow-none rounded-full text-gray-950  bg-gray-50 py-2 px-4" placeholder="e.g. jonedoe@gmail.com" />
                  </Field>
                  <span class="text-warning font-bold inline text-nowrap">{{ errors.email }}</span>
                </div>
              </div>
              <Field name="recaptcha" type="hidden" :model-value="reCaptchaV2Token" />
              <div :class="errors.recaptcha ? 'divRecaptchaError' : 'divRecaptcha'">
                <vue-recaptcha :sitekey="accountStore.reCaptchaSiteKey" size="normal" theme="light" hl="en"
                  class="clsRecaptcha" :loading-timeout="recaptchaTimeout" @verify="recaptchaVerified"
                  @expire="recaptchaExpired" @error="recaptchaError">
                </vue-recaptcha>
              </div>
              <div class="flex space-x-4 pt-3">
                <button class=" bg-blue-600 bg-gradient-to-r z-50 from-[#0061ff] to-[#00daff] shadow-md hover:brightness-125 duration-500 
                   transition fw-medium py-2 text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100" type="submit">
                  Submit
                </button>
                <router-link to="/login"
                  class="underline with-border transition fw-medium z-50 py-2 rounded-1 text-white fs-md-15 fs-lg-16 d-block w-100 text-center">
                  Go to Sign In
                </router-link>
              </div>
            </Form>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import vueRecaptcha from 'vue3-recaptcha2';
import { ref } from "vue";
import { useAccountStore, useAlertStore } from '@/stores';
const alertStore = useAlertStore();
const accountStore = useAccountStore();
const schema = Yup.object().shape({
  email: Yup.string().required('Email is required').email("Invalid email format"),
  recaptcha: Yup.string().required('You must confirm you are not a robot'),
});

const recaptchaTimeout = ref(30000);
const reCaptchaV2Token = ref('');

function recaptchaVerified(response) {
  reCaptchaV2Token.value = response;
}

function recaptchaExpired() {
  reCaptchaV2Token.value = '';
}

function recaptchaError(reason) {
  reCaptchaV2Token.value = '';
  alertStore.error("reCaptcha error");
}

async function onSubmit(values) {
  try {
    await accountStore.forgotpassword(values);
  } catch (error) {
    console.error(error)
  }
}

</script>
