<template>
  <div class="card mb-25 border-0 rounded-0 bg-white profile-settings-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" v-if="user">
      <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
        <div class="row ">
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Full Name
              </label>
              <Field name="name" v-slot="{ field }" v-model="user.name">
                <input v-bind="field" type="text" class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. Jone Doe" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.name }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Username
              </label>
              <Field name="user_name" v-slot="{ field }" v-model="user.user_name">
                <input v-bind="field" type="text" class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. Smith" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.user_name }}</span>

            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Email Address
              </label>
              <Field name="email" v-slot="{ field }" v-model="user.email">
                <input v-bind="field" type="email" disabled class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. adam127704@gmail.com" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.email }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Address
              </label>
              <Field name="address" v-slot="{ field }" v-model="user.address">
                <input v-bind="field" type="text" class="form-control shadow-none rounded-0 text-black"
                  placeholder="Set your address here" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.address }}</span>

            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Country
              </label>
              <Field name="country" v-slot="{ field }" v-model="selectedCountry">

                <country-select v-bind="field" name="country" :country="selectedCountry" topCountry="US"
                  :countryName="true" class="text-xs bg-gray outline-none w-full border text-black select-border px-[20px]"
                  style="height: 44px;" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.country }}</span>


            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Region
              </label>
              <Field name="city" v-slot="{ field }" v-model="selectedRegion" >
                <region-select v-bind="field" name="city" :country="selectedCountry" :region="selectedRegion"
                  :countryName="true" :regionName="true"
                  class="text-xs bg-gray outline-none w-full border text-black select-border px-[20px]" style="height: 44px;" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.city }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Password
              </label>
              <Field name="password" v-slot="{ field }">
                <input v-bind="field" type="password" class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. **************" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.password }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Confirm Password
              </label>
              <Field name="confirm_password" v-slot="{ field }">
                <input v-bind="field" type="password" class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. **************" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.confirm_password }}</span>
            </div>
          </div>
          <div v-if="user.role === 'admin'" class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Contact Link
              </label>
              <Field name="contact" v-slot="{ field }" v-model="user.contact">
                <input v-bind="field" type="text" class="form-control shadow-none rounded-0 text-black"
                  placeholder="Contact Link" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.contact }}</span>
            </div>
          </div>
          <div v-if="user.role === 'admin'" class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Cryptomus Merchant ID
              </label>
              <Field name="mid" v-slot="{ field }" v-model="user.mid">
                <input v-bind="field" type="text" class="form-control shadow-none rounded-0 text-black"
                  placeholder="Cryptomus Merchant ID" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.mid }}</span>
            </div>
          </div>
          <div v-if="user.role === 'admin'" class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Cryptomus Payment API Key
              </label>
              <Field name="apikey" v-slot="{ field }" v-model="user.apikey">
                <input v-bind="field" type="text" class="form-control shadow-none rounded-0 text-black"
                  placeholder="Cryptomus Payment API Key" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.apikey }}</span>
            </div>
          </div>
          <div v-if="user.role === 'admin'" class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                SMTP Sender Email
              </label>
              <Field name="smtpsender" v-slot="{ field }" v-model="user.smtpsender">
                <input v-bind="field" type="text" class="form-control shadow-none rounded-0 text-black"
                  placeholder="SMTP Sender Email" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.smtpsender }}</span>
            </div>
          </div>
          <div v-if="user.role === 'admin'" class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                SMTP Sender Username
              </label>
              <Field name="smtpuser" v-slot="{ field }" v-model="user.smtpuser">
                <input v-bind="field" type="text" class="form-control shadow-none rounded-0 text-black"
                  placeholder="SMTP Sender Username" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.smtpuser }}</span>
            </div>
          </div>
          <div v-if="user.role === 'admin'" class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                SMTP Server Host
              </label>
              <Field name="smtphost" v-slot="{ field }" v-model="user.smtphost">
                <input v-bind="field" type="text" class="form-control shadow-none rounded-0 text-black"
                  placeholder="SMTP Server Host" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.smtphost }}</span>
            </div>
          </div>
          <div v-if="user.role === 'admin'" class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                SMTP Server Password
              </label>
              <Field name="smtppass" v-slot="{ field }" v-model="user.smtppass">
                <input v-bind="field" type="text" class="form-control shadow-none rounded-0 text-black"
                  placeholder="SMTP Server Password" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.smtppass }}</span>
            </div>
          </div>
          <div v-if="user.role === 'admin'" class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                SMTP Server Port
              </label>
              <Field name="smtpport" v-slot="{ field }" v-model="user.smtpport">
                <input v-bind="field" type="number" class="form-control shadow-none rounded-0 text-black"
                  placeholder="SMTP Server Port" />
              </Field>
              <span class="text-warning font-bold inline text-nowrap">{{ errors.smtpport }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center justify-content-between">
            <button class="rounded-1 border-1 btn btn-outline-primary " type="submit">
              Save Profile
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>

</template>

<script setup>

import { storeToRefs } from "pinia"
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import { ref } from "vue";
import { useAccountStore } from "@/stores";
const accountStore = useAccountStore();

const schema = Yup.object().shape({
  email: Yup.string().required('Email is required').email("Invalid email format"),
  // password: Yup.string().required('Password is required').min(8),
  confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Passwords do not match'),
  user_name: Yup.string().required('User Name is required'),
  name: Yup.string().required('Full Name is required'),
  address: Yup.string().required('Address is required'),
  country: Yup.string().required('Country is required'),
  city: Yup.string().required('City is required'),
  apikey: Yup.string().required('API Key is required'),
  mid: Yup.string().required('MID is required'),
  smtpsender: Yup.string().required('SMTPSENDER is required'),
  smtphost: Yup.string().required('SMTPHOST is required'),
  smtpuser: Yup.string().required('SMTPUSER is required'),
  smtppass: Yup.string().required('SMTPPASS is required'),
  smtpport: Yup.string().required('SMTPPORT is required'),
  contact: Yup.string().required('Contact Link is required'),
});

const { user } = storeToRefs(accountStore)
const selectedCountry = ref(user.value.country)
const selectedRegion = ref(user.value.city)


async function onSubmit(values) {
  try {
    await accountStore.updateAccountAll(values);
    // await accountStore.udpateAccount(values);
  } catch (error) {
    console.error(error)
  }
}
// function deleteAccount() {
//   alert('delete')
// }
</script>